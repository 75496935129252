import parse from 'html-react-parser'
import reduce from 'lodash/reduce'
import camelCase from 'lodash/camelCase'

const isInternalLink = url => url ? (url.startsWith('/') || url.startsWith(process.env.GATSBY_SITE_URL)) : false

const getSlugFromFullUrl = url => (url && url.startsWith(process.env.GATSBY_SITE_URL) ? url.replace(process.env.GATSBY_SITE_URL, '') : url)

const decodeText = text => {
    const cleanText = parse(text)
    if (!!cleanText[0] && !!cleanText[0].props && !!cleanText[0].props.children) {
        return cleanText[0].props.children
    }
    return cleanText
}

const encodeQueryData = data => {
    const ret = []
    for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
}

const splitQueryString = url => {
    const query = {}
    const urlParts = url.split('?').pop().split('&')

    for (let part of urlParts) {
        const queryParts = part.split('=')
        query[decodeURIComponent(queryParts[0])] = decodeURIComponent(queryParts.pop() || true)
    }

    return query
}

const copyText = (text, node) => {
    if (node) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange()
            range.moveToElementText(node)
            range.select()
        } else if (window.getSelection) {
            const selection = window.getSelection()
            const range = document.createRange()
            range.selectNodeContents(node)
            selection.removeAllRanges()
            selection.addRange(range)
        }
    }

    // Hightlighting is required for copy to work in this way (old browsers)
    if (!navigator.clipboard) {
        return document.execCommand('copy')
    }

    return navigator.clipboard.writeText(text)
}

const stripProtocol = url => url.replace(/(^\w+:|^)\/\//, '');

const urlBase64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}


const forceDownload = (blob, filename) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

const cssToObj = (styles) => reduce(styles, (result, style) => {
    if(style){
        const properties = style.split(/:(.*)/s) // Split on first occurance incase url is in string
        result[camelCase(properties[0])] = properties[1]
        return result;
    }
}, {}) || {}

// Current blob size limit is around 500MB for browsers
const downloadResource = (url, filename) => {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
}

export { isInternalLink, getSlugFromFullUrl, decodeText, encodeQueryData, copyText, splitQueryString, stripProtocol, urlBase64ToUint8Array, cssToObj, downloadResource}

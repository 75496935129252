import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import faviconWhite from '../../assets/images/favicons/favicon-white.png'
import faviconBlack from '../../assets/images/favicons/favicon-black.png'

const SEO = ({ metaCleanTitle, metaTitle, metaImage, metaImageAlt, metaDescription, path }) => {
    const prefersDarkMode = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

    return (
        <StaticQuery
            query={graphql`
                query HeadingQuery {
                    site {
                        siteMetadata {
                            title
                            siteUrl
                            description
                            keywords
                            socialAlt
                        }
                    }
                    socialImage: file(relativePath: { regex: "/social/theCoalition/" }) {
                        publicURL
                    }
                }
            `}
            render={({ site, socialImage }) => (
                <Helmet>
                    <title>{metaCleanTitle || site.siteMetadata.title}</title>
                    <meta name='robots' content='Index' />
                    <meta
                        name='viewport'
                        content='width=device-width, height=device-height, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover'
                    />
                    <meta name='keywords' content={site.siteMetadata.keywords} />
                    <meta name='description' content={metaDescription || site.siteMetadata.description} />
                    <meta name='image' content={metaImage || `${site.siteMetadata.siteUrl}${socialImage.publicURL}`} />
                    <meta property='og:title' content={metaTitle || site.siteMetadata.title} />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:url'
                        content={
                            path
                                ? path.endsWith('/')
                                    ? `${site.siteMetadata.siteUrl}${path}`.slice(0, -1)
                                    : `${site.siteMetadata.siteUrl}${path}`
                                : site.siteMetadata.siteUrl
                        }
                    />
                    <meta property='og:image' content={metaImage || `${site.siteMetadata.siteUrl}${socialImage.publicURL}`} />
                    <meta property='og:image:alt' content={metaImageAlt || site.siteMetadata.socialAlt} />
                    <meta property='og:site_name' content={metaTitle || site.siteMetadata.title} />

                    <meta name='twitter:title' content={metaTitle || site.siteMetadata.title} />
                    <meta name='twitter:description' content={metaDescription || site.siteMetadata.description} />
                    <meta name='twitter:image' content={metaImage || `${site.siteMetadata.siteUrl}${socialImage.publicURL}`} />
                    <meta name='twitter:image:alt' content={metaImageAlt || site.siteMetadata.socialAlt} />
                    <meta name='twitter:card' content='summary_large_image' />

                    <html lang='en-us' />
                    <link rel='icon' href={prefersDarkMode ? faviconWhite : faviconBlack} type='imge/png' />
                    <link href='https://use.fontawesome.com/releases/v5.0.1/css/all.css' rel='preload' as='style' />
                </Helmet>
            )}
        />
    )
}

export default SEO

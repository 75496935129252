import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'tcweb-material-components/core/poly/get'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'

import GlobalStyles from 'tcweb-material-components/core/globalStyles'
// import usePushNotification from '../../hooks/usePushNotification'
import LocaleContext from '../context/localeContext'
import { decodeText } from '../../utilities/helpers'

import theme from '../../theme/dark/'
import styles from '../../styles'
import '../../styles/vendor/gutenberg-style.css'
import '../../styles/vendor/gutenberg-theme.css'
import '../../styles/vendor/cover-block.css'
import SEO from '../seo/seo'

const Layout = (props) => {
    const { children, pageContext } = props
    // const pushNotificationData = usePushNotification()

    const prefersDarkMode = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const hasFeaturedImage = get(props, 'data.page.featuredImage')

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    socialAlt
                    siteUrl
                }
            }
            socialImage: file(relativePath: { regex: "/social/theCoalition/" }) {
                publicURL
            }
        }
    `)

    const cleanTitle = !!props.data && !!props.data.page && !!props.data.page.title ? decodeText(props.data.page.title) : null

    const localeData = {
        locale: pageContext.locale,
        cmsLocale: pageContext.cmsLocale,
        isImplicit: pageContext.isImplicit
    }

    return (
        <>
            <SEO
                metaImage={
                    hasFeaturedImage &&
                    `${get(props, 'data.site.siteMetadata.siteUrl')}${get(
                        props,
                        'data.page.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src'
                    )}`
                }
                metaImageAlt={get(props, 'data.page.featuredImage.node.altText')}
                metaTitle={!!pageContext && !!cleanTitle && `${data.site.siteMetadata.title} | ${cleanTitle}`}
                path={props.path}
            />

            <LocaleContext.Provider value={localeData}>
                {/* <PushNotificationContext.Provider value={pushNotificationData}> */}
                    {/* <I18nextProvider i18n={i18n}> */}
                        <ThemeProvider type={theme.type || 'dark'} theme={theme} parent>
                            <GlobalStyles styles={styles} />
                            <CssBaseline />
                            {children}
                        </ThemeProvider>
                    {/* </I18nextProvider> */}
                {/* </PushNotificationContext.Provider> */}
            </LocaleContext.Provider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout

import bahnschriftFont from '../../assets/fonts/bahnschrift.ttf'
import interFont from '../../assets/fonts/Inter.ttf'
import commissionerFont from '../../assets/fonts/Commissioner.ttf'
import { makeStyles } from '@material-ui/core/styles'

/*
    Typography classes
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

export default ({ typography, breakpoints }) => {

    return ({
        ...makeStyles({
            '@font-face' : [{
                fontFamily: 'bahnschrift',
                fontDisplay: 'swap',
                src: `url(${bahnschriftFont}) format('truetype')`,
                fontWeight: '300 1000',
                fontStretch: '75% 100%'
            },
            {
                fontFamily: 'Commissioner',
                fontDisplay: 'swap',
                src:`url(${commissionerFont}) format('truetype')`,
                fontWeight: '800',
                // fontStretch: '75% 100%'
            },
            {
                fontFamily: 'inter',
                fontDisplay: 'swap',
                src:`url(${interFont}) format('truetype')`,
                fontWeight: '00',
            },]
        })(),
        // Target Safari 10.1+
        // @mixin target-Safari {
        //     @media not all and (min-resolution: 0.001dpcm) {
        //         @supports (-webkit-appearance: none) {
        //             @content;
        //         }
        //     }
        // }
        // @include target-Safari {
        //     @font-face {
        //         font-family: 'bahnschrift';
        //         font-display: swap;
        //         src: url('../assets/fonts/bahnschrift.ttf') format('truetype');
        //         font-weight: normal;
        //         font-stretch: 75% 100%;
        //     }
        // }

        //TODO: Fallback font structure
        'body': { ...typography.body1, fontFamily: 'Commissioner',[breakpoints.up('md')]:{ ...typography.desktop.body1 } },
        '.p, p': { ...typography.body1, fontFamily: 'inter',[breakpoints.up('md')]:{ ...typography.desktop.body1 } },
        '.h1, h1': { ...typography.h1, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h1 } },
        '.h2, h2': { ...typography.h2, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h2 } },
        '.h3, h3': { ...typography.h3, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h3 } },
        '.h4, h4': { ...typography.h4, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h4 } },
        '.h5, h5': { ...typography.h5, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h5 } },
        '.h6, h6': { ...typography.h6, fontFamily: 'Commissioner', [breakpoints.up('md')]:{ ...typography.desktop.h6 } }
    })
}
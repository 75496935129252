const commonHeading = {
	fontFamily: ['"Commissioner"', "Arial", "sans-serif"].join(", "),
	textTransform: "capitalize",
}

const theme = {
	type: 'dark',
	scrollbar: {
		style: 'none', // oneOf['none', 'default', 'primary']
	},
	navigation: {
		number: 1,
		tablet: {
			number: 1,
			//TODO: Experiment with tablet nav
		},
		desktop: {
			number: 1,
			//TODO: May be unnecessary
			height: '4.5em',
		}
	},
	scratch: {
		tablet: { height: '42' },
		desktop: { height: '42' }
	},
	palette: {
		primary: {
			lightest: "#c9c9c9",
			light: '#E43027',
			main: '#E43027',
			contrastText: '#FFFFFF'
		},
		secondary: {
			light: '#757575',
			main: '#000000',
			border: '#757575',
			contrastText: '#FFFFFF'
		},
		border:{
			primary: '#373A3C',
			secondary: '#2d2d2d',
			contrast: '#FFFFFF',
		},
		background: {
			light: '#2e2e2e',
			default: '#070707',
			primary: {
				main: '#000000'
			}
		},
		text:{
			primary: '#FFFFFF',
			secondary: '#959393',
			active: '#E43027',
			link: '#E43027',
		},
		site: {
			background: '#000000',
			activeText: '#E43027',
			mobile: {
				contrastText: '#E4E4E4',
				activeText: '#E43027'
			}
		},
		button: {
			labelBase: {
				transform: 'translateY(-1px)',
				fontFamily: 'Commissioner'
			},
			contained: {
				primary: {
					background: '#BF0A0A',
					borderColor: '#BF0A0A'
				}
			},
			outline: {
				quintary: {
					borderColor: '#373A3C',
					hover: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					},
					focus: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					}
				}
			},
			text: {
				primary: {
					hover: {
						color: '#E43027'
					},
					focus: {
						color: '#E43027'
					}
				}
			}
		}
	},
	typography: { // TODO: When all sites have these styles, change at custom components theme level
        display1: { ...commonHeading },
        h1: { ...commonHeading },
        h2: { ...commonHeading },
        h3: { ...commonHeading },
        h4: { ...commonHeading },
        h5: { ...commonHeading },
        h6: { ...commonHeading },
        desktop: {
            display1: { ...commonHeading },
            h1: { ...commonHeading },
            h2: { ...commonHeading },
            h3: { ...commonHeading },
            h4: { ...commonHeading },
            h5: { ...commonHeading },
            h6: { ...commonHeading }
        }
    }
}

module.exports = theme
